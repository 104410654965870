<template>
  <v-card>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12">
            <p>
              Hier kann eine Bestellung manuell angelegt werden. Für Kampagnen,
              die keinem Produkt zugewiesen werden können, verwendet bitte das
              Produkt "00 - Basic Einzelkampagne".
            </p>
          </v-col>

          <v-col cols="12">
            <v-combobox
              v-model="selectedCompany"
              :items="companies"
              :rules="[(v) => !!v || 'Firma ist ein Pflichtfeld']"
              label="Firma"
              item-text="name"
              item-value="id"
              hide-details
              required
            ></v-combobox>
          </v-col>

          <v-col cols="12">
            <v-combobox
              v-model="selectedProduct"
              :items="products"
              :rules="[(v) => !!v || 'Produkt ist ein Pflichtfeld']"
              label="Produkt"
              item-text="name"
              item-value="id"
              hide-details
              required
            ></v-combobox>
          </v-col>

          <!-- VORERST RAUS: <v-col cols="12">
            <v-checkbox
              v-model="enterBuyer"
              hide-details
              label="Einen Käufer anlegen"
            ></v-checkbox>
          </v-col> -->
        </v-row>

        <v-row v-if="enterBuyer">
          <v-col cols="12">
            <h2>Besteller</h2>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="firstName"
              :rules="[(v) => !!v || 'Vorname ist ein Pflichtfeld']"
              label="Vorname"
              hide-details
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="lastName"
              :rules="[(v) => !!v || 'Nachname ist ein Pflichtfeld']"
              label="Nachname"
              hide-details
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="email"
              type="email"
              :rules="emailRules"
              label="E-mail"
              hide-details
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="phone"
              type="tel"
              :rules="[(v) => !!v || 'Telefon ist ein Pflichtfeld']"
              label="Telefon"
              hide-details
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="9">
            <v-text-field
              v-model="street"
              :rules="[(v) => !!v || 'Straße ist ein Pflichtfeld']"
              label="Straße"
              hide-details
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field
              v-model="streetnumber"
              :rules="[(v) => !!v || 'Hausnummer ist ein Pflichtfeld']"
              label="Hausnummer"
              hide-details
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="zip"
              :rules="[(v) => !!v || 'Postleitzahl ist ein Pflichtfeld']"
              label="Postleitzahl"
              hide-details
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="8">
            <v-text-field
              v-model="city"
              :rules="[(v) => !!v || 'Ort ist ein Pflichtfeld']"
              label="Ort"
              hide-details
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">
        Bestellung anlegen
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
export default {
  name: "CreateOrder",

  components: {},

  props: [],

  data() {
    return {
      fetchingData: false,
      enterBuyer: false,
      companies: [],
      products: [],
      selectedCompany: null,
      selectedProduct: null,
      valid: false,
      name: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      street: "",
      streetnumber: "",
      zip: "",
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      select: null,
      items: ["Item 1", "Item 2", "Item 3", "Item 4"],
      checkbox: false,
    };
  },

  created: function() {
    this.getCompanies();
  },

  methods: {
    async getProducts() {
      this.preloader = true;
      let response = await this.getRequest("products");
      this.preloader = false;

      if (response.status == 200) {
        this.products = response.data.data;
      }
    },

    async getCompanies() {
      this.preloader = true;
      let response = await this.getRequest("companies");

      this.getProducts();

      if (response.status == 200) {
        this.companies = response.data.data;
      }
    },

    async createOrder() {
      /* create new entry */
      console.log(this.selectedCompany);
      var formData = new FormData();
      formData.append("companies_id", this.selectedCompany.id);
      formData.append("products_id", this.selectedProduct.id);

      this.fetchingData = true;
      let response = await this.postRequest("orders", "", formData);
      this.fetchingData = false;

      if (response.status === 200) {
        this.$emit("newOrder", response.data.data);
      }
    },

    validate() {
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        this.createOrder();
      }
    },
    /* reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }, */
  },

  mixins: [apiRequest, helpers],
};
</script>

<template>
  <div id="page">
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="12">
        <DataTable
          @tableAction="emittedAction"
          :items="items"
          :headers="headers"
          :itemActions="{
            edit: true,
          }"
          :search="search"
          :preloader="fetchingData"
        />
        <div class="caption">*) Kundenangabe</div>
      </v-col>
    </v-row>

    <!-- <pre>{{ items }}</pre> -->

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fixed
          dark
          fab
          bottom
          right
          color="green"
          v-bind="attrs"
          v-on="on"
          @click="createOrderDialog = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Verkauf manuell anlegen</span>
    </v-tooltip>

    <v-dialog
      v-model="createOrderDialog"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
      persistent
      :key="componentKey"
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-toolbar-title>Verkauf manuell anlegen</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="createOrderDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <CreateOrder @newOrder="mutateItems" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <AreYouSureDialog
      title="Soll der Verkauf storniert werden?"
      text="Wenn du den Verkauf tatsächlich löschen möchtest, klicke auf 'Ja'. Ansonsten klicke auf 'Nein'."
      :dialog="dialog"
      @dialogEmit="dialogEmit"
    />
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import PageHeader from "@/components/ui/PageHeader.vue";
import AreYouSureDialog from "@/components/ui/AreYouSureDialog.vue";
import CreateOrder from "@/components/orders/CreateOrder.vue";
import DataTable from "@/components/ui/DataTable.vue";
export default {
  name: "Orders",

  components: {
    PageHeader,
    AreYouSureDialog,
    CreateOrder,
    DataTable,
  },

  data() {
    return {
      componentKey: 0,
      pageTitle: "Bestellungen",
      pageDescription: `<p>Hier werden die Bestellungen verwaltet</p>`,
      createOrderDialog: false,
      title: "",
      fetchingData: false,
      items: [],
      headers: [],
      search: "",
      dialog: false,
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    mutateItems(value) {
      this.items.unshift(value); // add to top
      this.createOrderDialog = false;
      location.reload();
    },

    dialogEmit(value) {
      if (value) {
        /* ANSWER: TRUE */

        this.cancelSale();
        this.dialog = false;

        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Verkauf wurde erfolgreich storniert.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      } else {
        /* ANSWER: FALSE */

        this.dialog = false;

        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Verkauf wurde nicht storniert.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async createNew() {
      /* create new entry */
      let response = await this.postRequest("orders");

      if (response.status === 200) {
        let newItem = response.data.data;
        this.items.splice(0, 0, newItem);

        this.$router.push({
          name: "sales.orders.:id",
          params: { id: newItem.id },
        });
      }
    },

    editItem(id) {
      this.$router.push({
        name: "sales.orders.:id",
        params: { id: id },
      });
    },

    emittedAction(value) {
      if (value.action === "editItem") {
        this.$router.push({
          name: "sales.orders.:id",
          params: { id: value.itemId },
        });
      }
    },

    buildCompanyNames(buyersCompanyName, companyNames) {
      if (companyNames && companyNames[0] !== null) {
        // loop through array ans return a comma separated string
        let result = "";
        companyNames.forEach((item) => {
          if (item) {
            result += item + ", ";
          }
        });
        return result.slice(0, -2); // remove last comma
      } else {
        return buyersCompanyName + "*";
      }
    },

    buildStartDates(startDates) {
      if (startDates) {
        // loop through array ans return a comma separated string
        let result = "";
        startDates.forEach((item) => {
          if (item) {
            result += this.formatDate(item) + ", ";
          }
        });
        return result.slice(0, -2); // remove last comma
      } else {
        return "";
      }
    },

    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest("ordersOverview"); // await data from mixin
      this.fetchingData = false;

      if (response.status === 200) {
        const result = response.data.data;

        /* Ummappen:
        Hier baue ich mir quasi ein neues Array auf, um die Suchfunktion aktiv zu halten.
        Hier wird z.B. die HTML-Liste der Produkte gebaut und ausgegeben.
        Kann man auch über Slots machen, dann kann man danach aber nicht mehr suchen. */

        result.forEach((item) => {
          const newItem = {
            id: item.id,
            planMonths_id: item.planMonths_id,
            createdF: this.formatDate(item.created),
            isApproved: item.isApproved,
            buyersName: item.buyersName,
            contextJmp: item.products[0].contextJmp,
            price: item.planMonths_id ? "0.00" : item.products[0].price,
            companies_name: this.buildCompanyNames(
              item.buyersCompanyName,
              item.companyNames
            ),
            startDates: this.buildStartDates(item.buyerStartDates),
            productsList:
              "<ul class='productslist'>" +
              this.buildProductsList(item.products) +
              "</ul>",
            productsArray: item.products,
          };

          this.items.push(newItem);
        });

        console.log(this.items);

        this.headers = [
          { text: "ID", value: "id" },
          { text: "Quelle", value: "isJmpProduct" },
          { text: "Bestelldatum", value: "createdF" },
          { text: "Name", value: "buyersName" },
          { text: "Company", value: "companies_name" },
          { text: "Start", value: "startDates" },
          { text: "Produkte", value: "productsList" },
          { text: "Preis", value: "price", align: "end" },
          { text: "Actions", value: "actions", sortable: false },
        ];
      }
    },

    buildProductsList(obj) {
      /* HTML-Produktliste für die Tabelle */
      let result = "";

      obj.forEach((item) => {
        if (item) {
          if (item.isApproved === "1") {
            result += `<li style="color:green;"><span class="mdi mdi-check-circle-outline green--text"></span> ${item.name}</li>`;
          } else {
            result += `<li style="color:red;font-weight:bold;"><span class="mdi mdi-alert-circle-outline red--text"></span> ${item.name}</li>`;
          }
        }
      });

      return result;
    },

    buildBuyerName(item) {
      if (item.included.buyer.lastName) {
        return (
          item.included.buyer.firstName + " " + item.included.buyer.lastName
        );
      } else {
        return "-";
      }
    },
  },

  mixins: [apiRequest, helpers],
};
</script>

<style>
ul.productslist {
  list-style: none;
  padding-left: 0;
}
</style>
